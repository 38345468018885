import mascot from './mascot-hello.svg';
import helloBubble from './hello-bubble.svg';
import { useEffect, useRef } from 'react';

export default function Hello() {
    const bubbleDiv = useRef();

    useEffect(() => {

        const intervalId = setInterval(() => {
            bubbleDiv.current.classList.remove('pop');
            setTimeout(() => {
                bubbleDiv.current.classList.add('pop');
            }, 100);
        }, 4000);
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    return <div className="hello">
        <a target="_blank" rel="noreferrer noopener" href="https://wa.me/6281121101000">
            <div className="bubble" ref={bubbleDiv} dangerouslySetInnerHTML={{
                __html: `<svg version="1.1" id="Layer_1"
                viewBox="0 0 60 39">
            <style type="text/css">
                .bubble-st0{fill:#F8EE5D;}
                .bubble-st1{fill:#155AA7;}
            </style>
            <path id="bubble" class="bubble-st0" d="M47.8,4.3C33-3.2,12.2,0.1,5.4,6.5c-6.8,6.4-7.6,18.6,0.4,24.9c8,6.3,32.9,4.3,40.4,1
                c7.5-3.3,15.2,16.9,13.7-0.7C58.4,14.1,56.8,8.8,47.8,4.3z"/>
            <path id="H" class="bubble-st1" d="M16.8,12.2c0.1-0.2,0.2-0.3,0.5-0.3c0.2-0.1,0.5-0.1,0.8-0.1c0.3,0,0.6,0,0.8,0.1s0.3,0.1,0.4,0.2
                c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.1,0.5,0.1,0.9v9.1c0,0.3,0,0.4,0,0.6c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.3-0.6,0.4-1.3,0.4
                c-0.8,0-1.3-0.2-1.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.9V19h-4.6v3.4c0,0.3,0,0.4,0,0.6c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.3-0.6,0.4-1.3,0.4
                c-0.8,0-1.3-0.2-1.4-0.6C9,23,9,22.7,9,22.3v-9.1c0-0.3,0-0.4,0-0.6c0-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.6-0.4,1.3-0.4
                c0.8,0,1.3,0.2,1.4,0.6c0.1,0.2,0.1,0.5,0.1,0.9v3.4h4.6v-3.4c0-0.3,0-0.4,0-0.6C16.7,12.5,16.7,12.3,16.8,12.2z"/>
            <path id="E" class="bubble-st1" d="M29.8,18.4c0,0.7-0.2,1.2-0.5,1.5c-0.3,0.3-0.7,0.5-1.2,0.5h-4.1c0,0.3,0.2,0.6,0.6,0.8
                c0.4,0.2,0.8,0.3,1.1,0.3c0.7,0,1.2-0.1,1.6-0.2l0.2-0.1c0.3-0.1,0.5-0.2,0.7-0.2c0.4,0,0.7,0.2,0.9,0.7c0.1,0.3,0.2,0.5,0.2,0.8
                c0,1-1.2,1.5-3.7,1.5c-0.9,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.2-0.7-1.5-1.1c-0.7-0.9-1.1-1.9-1.1-3c0-1.4,0.5-2.5,1.4-3.3
                c0.9-0.8,2.1-1.3,3.5-1.3c1.6,0,2.8,0.5,3.5,1.6C29.6,17.1,29.8,17.7,29.8,18.4z M26.2,18.9c0.4,0,0.7-0.2,0.7-0.5
                c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.2-0.5-0.2-0.9-0.2c-0.4,0-0.7,0.1-1.1,0.4c-0.4,0.3-0.6,0.6-0.6,0.9H26.2z"/>
            <path id="L-1" class="bubble-st1" d="M34.1,20.9c0.4,0,0.7,0.1,0.8,0.3c0.2,0.2,0.3,0.6,0.3,1.2c0,0.6-0.1,1-0.3,1.2c-0.2,0.2-0.5,0.3-0.8,0.3
                c-1.2,0-2-0.3-2.5-0.8c-0.5-0.5-0.7-1.4-0.7-2.7v-7.9c0-0.3,0-0.4,0-0.6c0-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.6-0.4,1.3-0.4
                c0.7,0,1.1,0.2,1.3,0.5c0.1,0.2,0.1,0.3,0.2,0.4c0,0.1,0,0.3,0,0.6v7.9C34,20.7,34,20.9,34.1,20.9z"/>
            <path id="O" class="bubble-st1" d="M41.5,19.5c0-1.3,0.5-2.4,1.4-3.3c1-0.9,2.1-1.3,3.3-1.3c1.2,0,2.3,0.4,3.3,1.3c1,0.8,1.4,1.9,1.4,3.2
                c0,0.9-0.2,1.7-0.7,2.4c-0.5,0.7-1.1,1.2-1.8,1.6c-0.7,0.3-1.4,0.5-2.2,0.5s-1.5-0.2-2.2-0.5c-0.7-0.4-1.3-0.9-1.8-1.6
                C41.7,21.1,41.5,20.3,41.5,19.5z M45.1,20.6c0.4,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.4-0.3,0.6-0.7,0.6-1.2
                c0-0.5-0.2-0.9-0.5-1.2c-0.4-0.3-0.7-0.4-1.2-0.4c-0.4,0-0.8,0.1-1.2,0.4c-0.4,0.3-0.5,0.7-0.5,1.2C44.6,20,44.7,20.4,45.1,20.6z"/>
            <path id="L-2" class="bubble-st1" d="M39.1,20.6c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.7,0.1s0.7,0.1,0.9,0.3c0.3,0.2,0.4,0.6,0.4,1.2
                c0,0.6-0.1,1-0.4,1.2c-0.3,0.2-0.6,0.3-1.1,0.3c-2.4,0-3.7-1.2-3.7-3.5l0-7.9c0-0.3,0-0.4,0-0.6c0-0.1,0.1-0.3,0.2-0.4
                c0.2-0.3,0.6-0.4,1.3-0.4c0.7,0,1.1,0.2,1.3,0.5c0.1,0.2,0.2,0.6,0.2,1V20.6z"/>
            </svg>`
            }}>

            </div>
            <div className="mascot">
                <img src={mascot} alt="" />
            </div>
        </a>
    </div>
}