import { useEffect, useState, useRef } from 'react';
import { gsap, Power3, Power0 } from 'gsap';
import './style.scss';
const PageLoading = () => {
    const animationRef = useRef();
    useEffect(() => {
        gsap.set(".svg-fill", {y: 50, opacity: 0});
        let tl = gsap.timeline({ repeat: 0, yoyo: false });
        tl.to(".dotss-1", { duration: 0.5, backgroundColor: '#1d58ab' }, 1)
            .to(".dotss-2", { duration: 0.5, backgroundColor: '#57c4f1' }, 1.25)
            .to(".dotss-3", { duration: 0.5, backgroundColor: '#fcec1f' }, 1.5)
            .to(".dotss-4", { duration: 0.5, backgroundColor: '#fda622' }, 1.75)
            .to(".dotss-5", { duration: 0.5, backgroundColor: '#e5343b' }, 2)
            .to(".my-loading-page", { duration: 0.75, backgroundColor: 'white' }, 1.5)
            .to(".dotss-1", { duration: 0.5, opacity: 0 }, 2.5)
            .to(".dotss-2", { duration: 0.5, opacity: 0 }, 2.6)
            .to(".dotss-3", { duration: 0.5, opacity: 0 }, 2.7)
            .to(".dotss-4", { duration: 0.5, opacity: 0 }, 2.8)
            .to(".dotss-5", { duration: 0.5, opacity: 0 }, 2.9)
            .to(".st0", { y: 0, opacity: 1, duration: 0.5, fill: '#1d58ab' }, 2.7)
            .to(".st1", { y: 0, opacity: 1, duration: 0.5, fill: '#57c4f1' }, 2.8)
            .to(".st2", { y: 0, opacity: 1, duration: 0.5, fill: '#fcec1f' }, 2.9)
            .to(".st3", { y: 0, opacity: 1, duration: 0.5, fill: '#fda622' }, 3.0)
            .to(".st4", { y: 0, opacity: 1, duration: 0.5, fill: '#e5343b' }, 3.1)
            .to(".st0", { scale: 0.8, opacity: 0, duration: 0.5 }, 3.8)
            .to(".st1", { scale: 0.8, opacity: 0, duration: 0.5 }, 3.9)
            .to(".st2", { scale: 0.8, opacity: 0, duration: 0.5 }, 4)
            .to(".st3", { scale: 0.8, opacity: 0, duration: 0.5 }, 4.1)
            .to(".st4", { scale: 0.8, opacity: 0, duration: 0. }, 4.2)
            .to(".my-loading-page", {zIndex: -1, opacity: 0, duration: 0.5 }, 4.35)
    });
    return (
        <div className='my-loading-page'>
            <div className="loading-bar">
                <div className='dotss dotss-1'></div>
                <div className='dotss dotss-2'></div>
                <div className='dotss dotss-3'></div>
                <div className='dotss dotss-4'></div>
                <div className='dotss dotss-5'></div>
            </div>
            <div className='div-svg' dangerouslySetInnerHTML={{
                __html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 511 170" style="enable-background:new 0 0 511 170;" xml:space="preserve">
                <path class="st0 w svg-fill" d="M123.7,27.4H113c-2.3,0-4.4,1.5-5.2,3.6L90.7,88c-0.3,1-1.8,1-2-0.1L75.1,31c-0.7-2-2.5-3.3-4.5-3.6 c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.4-0.1h-0.1H59.3h-0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0c-2,0.3-3.8,1.6-4.5,3.6 L40.5,88c-0.3,1.1-1.7,1.1-2,0.1L21.3,31c-0.8-2.2-2.8-3.6-5.2-3.6H5.5c-3.8,0-6.4,3.8-5.2,7.3l22.6,72.5c1.6,4.3,5.7,7.2,10.3,7.2 h6.4H46c4.6,0,8.8-2.9,10.3-7.2l7.2-28c0.3-1.1,1.8-1.1,2,0l7.2,28c1.6,4.3,5.7,7.2,10.3,7.2h6.4H96c4.6,0,8.8-2.9,10.3-7.2 l22.6-72.5C130.1,31.1,127.5,27.4,123.7,27.4z"/>
                <path class="st1 a svg-fill" d="M508.5,120.3l-2.7-2.8c-2.9-3-7.7-3.6-11.2-1.2c-1.8,1.2-4.1,2.6-6.7,3.9c-10.5,5.4-21.7,8.1-33.5,8.1 c-14.2,0-27-5.8-40.6-11.9c-15.6-7-31.7-14.2-51.6-14.2c-19.2,0-35.1,6.8-50.5,13.4c-15.2,6.5-29.7,12.7-47.4,12.7 c-9.6,0-21.6,0-39-14.2C205.5,97.9,194.1,69.3,194,69l-0.2-0.5l-0.3-0.5c-2.2-4.3-7.4-16.9-10-23.5l-3.9-10.1 c-1.6-4.3-5.6-7.1-10.2-7.1H163h-6.4c-4.6,0-8.7,2.9-10.2,7.1l-28.1,71.9c-1.3,3.5,1.4,7.2,5.1,7.2H134c2.3,0,4.4-1.4,5.1-3.6 l23.6-59.6c0,0.1,0.1,0.1,0.1,0.1c0.8,2.1,8,19.9,11.4,26.7c1.8,4.4,14.6,34.7,37.5,53.4c23.3,19,41.6,19,52.6,19 c22.1,0,40-7.6,55.8-14.4c14-6,27.2-11.7,42.1-11.7c15.4,0,28.7,6,42.8,12.3c15.1,6.7,30.7,13.7,49.4,13.7c24.6,0,42.9-9.4,52.7-16 C511.7,130.5,512.3,124.2,508.5,120.3z"/>
                <path class="st2 h svg-fill" d="M291,26.9h-5.3c-4.4,0-7.8,3.5-7.8,7.8v15.1c0,4.3-3.5,7.8-7.8,7.8h-30.6c-3,0-5.5-2.4-5.5-5.4v3.2V34.7 c0-4.3-3.5-7.8-7.8-7.8h-5.3c-4.4,0-7.8,3.5-7.8,7.8v30.6v2v12.9c0,4.3,3.5,7.8,7.8,7.8h5.3c4.4,0,7.8-3.5,7.8-7.8v0.3 c0-3,2.4-5.4,5.5-5.4H270c4.4,0,7.8,3.5,7.8,7.8v20.2c0,4.3,3.5,7.8,7.8,7.8h5.3c4.4,0,7.8-3.5,7.8-7.8V34.7 C298.8,30.4,295.3,26.9,291,26.9z"/>
                <path class="st3 o-4 svg-fill" d="M400.9,26.9c-2.4-5.6-5.7-10.3-9.9-14.2c-4.2-3.9-9.3-6.9-15-9c-5.8-2.1-12.1-3.2-18.9-3.2 c-6.8,0-13.1,1.1-18.9,3.2c-5.8,2.1-10.8,5.1-15,9c-4.2,3.9-7.5,8.6-9.9,14.2c-2.4,5.6-3.5,11.8-3.5,18.7c0,6.8,1.2,13.1,3.5,18.7 c2.4,5.7,5.7,10.5,9.9,14.5c4.2,4,9.3,6.9,15,9.3c10.5,4.5,27.1,4,37.8,0c5.8-2.2,10.8-5.3,15-9.3c4.2-4,7.5-8.8,9.9-14.5 c2.4-5.6,3.5-11.9,3.5-18.7C404.4,38.7,403.2,32.4,400.9,26.9z M380.1,56.4c-1.2,3.3-2.9,6.1-5.2,8.5c-2.2,2.4-4.9,4.2-7.8,5.5 c-3,1.4-6.4,2-10,2H357c-3.7,0-7-0.7-10.1-2c-3-1.4-5.6-3.2-7.8-5.5c-2.2-2.4-3.8-5.2-5.1-8.5c-1.2-3.3-1.8-6.9-1.8-10.8 c0-3.7,0.6-7.2,1.8-10.5s2.9-6,5.1-8.3c2.2-2.3,4.8-4.2,7.8-5.5c3-1.4,6.4-2,10.1-2c3.7,0,7.1,0.7,10.1,2c3.1,1.4,5.7,3.2,7.8,5.5 c2.2,2.3,3.8,5.1,5.1,8.3c1.2,3.3,1.8,6.7,1.8,10.5C382,49.5,381.3,53.1,380.1,56.4z"/>
                <path class="st4 0-5 svg-fill" d="M503.2,48.8c-2.4-5.6-5.7-10.3-9.9-14.2c-4.2-3.9-9.3-6.9-15-9c-5.8-2.1-12.1-3.2-18.9-3.2s-13.1,1.1-18.9,3.2 c-5.8,2.1-10.8,5.1-15,9c-4.2,3.9-7.5,8.6-9.9,14.2c-2.4,5.6-3.5,11.8-3.5,18.7c0,6.8,1.2,13.1,3.5,18.7c2.4,5.7,5.7,10.5,9.9,14.5 c4.2,4,9.3,6.9,15,9.3c10.5,4.5,27.1,4,37.8,0c5.8-2.2,10.8-5.3,15-9.3c4.2-4,7.5-8.8,9.9-14.5c2.4-5.6,3.5-11.9,3.5-18.7 C506.8,60.6,505.6,54.3,503.2,48.8z M482.5,78.3c-1.2,3.3-2.9,6.1-5.2,8.5c-2.2,2.4-4.9,4.2-7.8,5.5c-3,1.4-6.4,2-10,2h-0.1 c-3.7,0-7-0.7-10.1-2c-3-1.4-5.6-3.2-7.8-5.5c-2.2-2.4-3.8-5.2-5.1-8.5c-1.2-3.3-1.8-6.9-1.8-10.8c0-3.7,0.6-7.2,1.8-10.5 c1.2-3.3,2.9-6,5.1-8.3c2.2-2.3,4.8-4.2,7.8-5.5c3-1.4,6.4-2,10.1-2c3.7,0,7.1,0.7,10.1,2c3.1,1.4,5.7,3.2,7.8,5.5 c2.2,2.3,3.8,5.1,5.1,8.3c1.2,3.3,1.8,6.7,1.8,10.5C484.3,71.4,483.7,75,482.5,78.3z"/>
            </svg>` }}>

            </div>

        </div>
    );
}

export default PageLoading;