import FirstBanner from "../../components/FirstBanner";
import splash_img from "../../assets/splash.webp";
import EventVenueList from "../../components/ThingsToDo/EventVenue";
import useFetch from "../../customFunction/useFetch";
import { motion } from "framer-motion";
import {useLanguage} from '../../lang/LangProvider';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.35, duration: 0.5 }
  },
  exit: {
    y: -30,
    opacity: 0,
    transition: { duration: 0.35 }
  }
}
const EventVenue = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { translate,currentLanguage} = useLanguage();

  const { data, isPending, error } = useFetch(apiUrl + 'venue?lang='+currentLanguage);
  document.title = "Event Venue | Wahoo Waterworld";
  return (
    <motion.div className="position-relative"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <FirstBanner color1="blue" color2="lightblue">
        <div className="button-title bg-yellow mx-auto mb-2">Event Venue</div>
        <h2 className="secondary-title text-center text-white">{translate('event_venue.sub_heading')}</h2>
      </FirstBanner>
      <div className="section">
        <div className="container">
          {isPending && ' '}
          {error && error.message}
          {data && <EventVenueList eventVenues={data} />}
        </div>
      </div>
      <img className="splash-bottom" src={splash_img} alt="splash" />
    </motion.div>
  );
};
export default EventVenue;
