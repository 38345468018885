import ImageClip from '../ui/ImageClip';
import './style.scss';
const ModalService = ({ handleClick, close, data }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <div className="modals">
            <div className="position-relative">
                <div className="button-title bg-orange">{data.name}</div>
                {/* <button className='close-modal'  onClick={close} onClick={() => handleClick()}><i className="fas fa-times"></i></button> */}
                <button className='close-modal' onClick={close} onClick={() => handleClick()}><i className="fas fa-times"></i></button>
                <div className="container">
                    <div className="modal-content">
                        <div className="list-item">
                            <div className='modalTop'>
                                <ImageClip src={baseUrl + data.image_url} />
                            </div>
                            <div className="content-list">
                                {data.label && data.label}
                                {data.regular_price &&
                                    <>
                                        <p><b>Regular</b>: {data.regular_price}</p>
                                        <p>{data.regular_note && `(${data.regular_note})`}</p>
                                    </>
                                }
                                {data.vip_price &&
                                    <>
                                        <p><b>VIP</b>: {data.vip_price}</p>
                                        <p>{data.vip_note && `(${data.vip_note})`}</p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-mbl-none bg-yellow aksen modal-content">
                    </div>
                </div>
                <svg width="0" height="0">
                    <clipPath id="svgClip" clipPathUnits="objectBoundingBox">
                        <path d="m 0.892 0.092 C 1.016 0.226 1.021 0.792 0.877 0.888 C 0.683 1.027 0.273 0.991 0.139 0.891 S -0.049 0.247 0.128 0.094 c 0.156 -0.108 0.541 -0.111 0.739 -0.017 Z"></path>
                    </clipPath>
                </svg>
            </div>
        </div>
    )
}

export default ModalService;