import BottomWave from "../components/ui/BottomWave";
import splash_img from "../assets/splash.webp";
import octo from "../assets/gurita.png";
import earthquakeBlack from "../assets/weather/earthquake-black.jpg";
import eartquakeAmber from "../assets/weather/earthquake-amber.jpg";
import earthquakeRed from "../assets/weather/earthquake-red.jpg";
import rainstromBlack from "../assets/weather/rainstrom-black.jpg";
import rainstromAmber from "../assets/weather/rainstrom-amber.jpg";
import rainstromRed from "../assets/weather/rainstrom-red.jpg";
import ListDiv from "../components/ListDiv";
import { motion } from "framer-motion";

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const WeatherArrangement = () => {
    const warnings = [
        {
            name: "Amber Rainstorm Warning",
            image: rainstromAmber,
            link: "#",
            desc: "Water World will remain open but some attractions may need to be suspended."
        },
        {
            name: "Red Rainstorm Warning",
            image: rainstromRed,
            link: "#",
            desc: "Water World will remain open but some attractions may need to be suspended."
        },
        {
            name: "Black Rainstorm Warning",
            image: rainstromBlack,
            link: "#",
            desc: "<p>If the warning is hoisted during the operating hours, Water World will remain open with limited operations.</p>&nbsp;<p>If the signal is in effect prior to the opening of Water World, Water World will delay opening for a minimum of two hours after the signal is lowered with the possibility of limited operations.</p>"
        },
    ];
    const warningsEartQuake = [
        {
            name: "Amber Earthquake Warning",
            image: eartquakeAmber,
            link: "#",
            desc: "Water World will remain open but some attractions may need to be suspended."
        },
        {
            name: "Red Earthquake Warning",
            image: earthquakeRed,
            link: "#",
            desc: "Water World will remain open but some attractions may need to be suspended."
        },
        {
            name: "Black Earthquake Warning",
            image: earthquakeBlack,
            link: "#",
            desc: "<p>If the warning is hoisted during the operating hours, Water World will remain open with limited operations.</p>&nbsp;<p>If the signal is in effect prior to the opening of Water World, Water World will delay opening for a minimum of two hours after the signal is lowered with the possibility of limited operations.</p>"
        },
    ];
    document.title = "Weather Arrangement | Wahoo Waterworld";

    return (
        <motion.div className="position-relative"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit">
            <div className="bg-color-blue section pb-0">
                <div className="container text-white text-center">
                    <h1 className="main-title mb-2 px-big-1">Weather Arrangement</h1>
                    <p className="w-75 mx-auto mb-big-1">In general, Wahoo Water World remains open in inclement weather, as we have many sheltered areas to protect visitors. However, in extremely adverse conditions such as when the BMKG issues a rainstorm warning or tropical cyclone signal, special arrangements will go into effect. Please refer to the list below to see the arrangements that correspond to each such condition.</p>
                </div>
                <div className="section pt-1">
                    <div className="ornaments">
                        <div className="left">
                            <img src={octo} alt="" className="float-anim" />
                        </div>
                    </div>
                    <BottomWave className='yellow' />
                </div>
            </div>
            <div className="section pt-0 yellow">
                <div className="container">
                    <h5 className="text-center secondary-title mb-big-1"><span className="text-blue">Rainstorm</span><span className="text-orange"> Warning Signals</span></h5>
                    <div className="layout-3">
                        {warnings && <ListDiv data={warnings} />}
                    </div>
                </div>
                <div className="mbl-div-space-2 div-space-4"></div>
            </div>
            <div className="section mbl-mt-big-0 mt-big-2 pt-0 position-relative z-index">
                <div className="top-wave-banner">
                    <div className="waveblue"></div>
                    <div className="wavewhite"></div>
                </div>
                <div className="container">
                    <h5 className="text-center secondary-title text-orange">Earthquake</h5>
                    <h5 className="text-center secondary-title text-blue">Warning Signals</h5>
                    <div className="layout-3 mt-big-2">
                        {warningsEartQuake && <ListDiv data={warningsEartQuake} />}
                    </div>
                </div>
            </div>
            <img className="splash-bottom" src={splash_img} />
        </motion.div>
    );
}

export default WeatherArrangement;