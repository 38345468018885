import { useState } from 'react';
import './style.scss';
import rounded from './rounded.svg';
import parse from 'html-react-parser';

const AccordionList = ({ data }) => {
    const [isActive, setIsActive] = useState(false);
    const [clicked, setClicked] = useState("0");
    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };
    return (
        <>
            {data.map((faqlist, index) => (
                <div key={faqlist.id} className="accordion">
                    <AccordionData faqlist={faqlist} onToggle={() => handleToggle(index)} active={clicked === index} />
                </div>
            ))}
        </>
    );
}

const AccordionData = ({ faqlist, onToggle, active }) => {
    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={onToggle} >
                <svg className='rounded-top' width="1126" height="12" viewBox="0 0 1126 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1126 12H563H0C0 5.37255 293 0 563 -3.05176e-05C873.936 -3.05176e-05 1126 5.37255 1126 12Z" fill="#D9D9D9" />
                </svg>
                <div className="accordion-title-text">{faqlist.name}</div>
                <div><i className={`arrow text-yellow fa-solid fa-sort-down ${active ? "rotate" : ""}`}></i></div>
                <svg className='rounded-bottom' width="1126" height="12" viewBox="0 0 1126 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1126 12H563H0C0 5.37255 293 0 563 -3.05176e-05C873.936 -3.05176e-05 1126 5.37255 1126 12Z" fill="#D9D9D9" />
                </svg>
            </div>
            {faqlist.content && <div className={`accordion-content ${active ? "open" : ""}`}>{parse(faqlist.content)}</div>}
            {faqlist.html && <div className={`accordion-content ${active ? "open" : ""}`}>{faqlist.html}</div>}
        </div>
    )
};

export default AccordionList;