import React, { createContext, useContext, useState, useEffect } from 'react';
import en from './en';
import id from './id';

const languages = {
  en,
  id,
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {

  
  const [currentLanguage, setCurrentLanguage] = useState('id'); 

  useEffect(() => {
    const savedLanguage = localStorage.getItem('appLanguage');
    if (savedLanguage && languages[savedLanguage]) {
      setCurrentLanguage(savedLanguage);
    }
  }, []);

  const changeLanguage = (lang) => {
    if (languages[lang]) {
      setCurrentLanguage(lang);

      localStorage.setItem('appLanguage', lang); 
    }

  };

  const translate = (key) => {
    const keys = key.split('.');
    let result = languages[currentLanguage];

    for (let k of keys) {
        if (result[k]) {
            result = result[k];
        } else {
            return key; // Return the key itself if translation is not found
        }
    }
    return result;
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage, translate}}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
