import FirstBanner from "../components/FirstBanner";
import splash_img from "../assets/splash.webp";
import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import PreLoader from "../components/PreLoader";
import { Oval } from 'react-loader-spinner'
const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}

const ContactUs = () => {
    document.title = "Contact Us | Wahoo Waterworld";
    const apiUrl = process.env.REACT_APP_API_URL;
    const [nameHidden, setNameHidden] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    // const [captcha, setCaptcha] = useState('');
    const [validName, setValidName] = useState(true);
    const [validSubject, setValidSubject] = useState(true);
    const [validPhone, setValidPhone] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validMessage, setValidMessage] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(null);
    const recaptchaRef = useRef();
    const handleSubmit = async (event) => {
        event.preventDefault();
        await recaptchaRef.current.executeAsync();
        const captchaResult = recaptchaRef.current;
        if (
            name === '' ||
            phone === '' ||
            email === '' ||
            subject === '' ||
            message === ''
        ) {
            if (!name) setValidName(false)
            if (!subject) setValidSubject(false)
            if (!phone) setValidPhone(false)
            if (!email) setValidEmail(false)
            if (!message) setValidMessage(false)
        }
        else {
            setIsSending(true);
            axios({
                method: "POST",
                url: apiUrl + "contactPost",
                mode: "no-cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                data: {
                    name: name,
                    phone: phone,
                    subject: subject,
                    email: email,
                    message: message,
                    nameHidden: nameHidden,
                    type: 'contact',
                    reCaptcha: captchaResult.getValue()
                }
            })
                // const res = await axios.post(apiUrl + "contactPost", post)
                .then(result => {
                    setName('')
                    setPhone('')
                    setSubject('')
                    setEmail('')
                    setMessage('')
                    setNameHidden('')
                    setIsSending(false)
                    setIsSent(result.data)
                    captchaResult.reset();
                    setTimeout(() => {
                        setIsSent(null)
                    }, 4000);
                })
                .catch(error => {
                    captchaResult.reset();
                    setIsSending(false)
                    setIsSent({ "sent": false })
                    setTimeout(() => {
                        setIsSent(null)
                    }, 4000);
                });
        }
    }
    return (
        <>
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <FirstBanner color1="grey" color2="lightblue">
                    <div className="button-title">Contact Us</div>
                </FirstBanner>
                <div className="position-relative">
                    <div className="section pt-2 pb-2">
                        <div className="container">
                            <div id="div-for-contact" className="bg-color-yellow  pt-big-2">
                                <h5 className="secondary-title text-blue text-center mb-big-1 ">Ceritakan pengalaman Wahoo kamu atau kirimkan pertanyaan.<br></br>Kami ingin mendengar cerita kamu!</h5>
                                <form className="form  mx-auto" id="group-form" onSubmit={handleSubmit}>
                                    {
                                        isSent && isSent.sent === true && <FlashMessage text="Pesan telah kami terima, akan kami balas secepatnya" />
                                    }
                                    {
                                        isSent && isSent.sent === false && <FlashMessage text="Telah terjadi kesalahan, mohon coba beberapa saat lagi" className="error" />
                                    }
                                    <div className={`form-control half required ${!validName ? `error` : ``}`} >
                                        <i className="fas text-red fa-user icon-input"></i>
                                        <input
                                            maxLength="50"
                                            placeholder="Nama"
                                            className={`input-control required ${!validName ? `error` : ``}`}
                                            type="text"
                                            onChange={event => {
                                                var maxlength = event.target.maxLength;
                                                setName(event.target.value.slice(0, maxlength));
                                                event.target.value.slice(0, maxlength) === '' ? setValidName(false) : setValidName(true)
                                            }}
                                            value={name}
                                            name="name" />
                                    </div>
                                    <div className={`form-control half required ${!validEmail ? `error` : ``}`} >
                                        <i className="fas text-red fa-envelope icon-input"></i>
                                        <input
                                            maxLength="50"
                                            placeholder="Email"
                                            id="email"
                                            type="email"
                                            name="email"
                                            className={`input-control required ${!validEmail ? `error` : ``}`}
                                            onChange={event => {
                                                var maxlength = event.target.maxLength;
                                                setEmail(event.target.value.slice(0, maxlength).slice(0, maxlength));
                                                event.target.value.slice(0, maxlength).slice(0, maxlength) === '' ? setValidEmail(false) : setValidEmail(true)
                                            }}
                                            value={email}
                                            autoComplete="off" />
                                    </div>
                                    <div className={`form-control half required ${!validSubject ? `error` : ``}`} >
                                        <i className="fas text-red fa-message-lines icon-input"></i>
                                        <input
                                            id="subject"
                                            maxLength="50"
                                            type="text"
                                            placeholder="Subjek"
                                            name="subject"
                                            className={`input-control required ${!validSubject ? `error` : ``}`}
                                            onChange={event => {
                                                var maxlength = event.target.maxLength;
                                                setSubject(event.target.value.slice(0, maxlength));
                                                event.target.value.slice(0, maxlength) === '' ? setValidSubject(false) : setValidSubject(true)
                                            }}
                                            value={subject}
                                            autoComplete="off" />
                                    </div>
                                    <div className={`form-control half required ${!validPhone ? `error` : ``}`} >
                                        <i className="fas text-red fa-phone icon-input"></i>
                                        <input
                                            placeholder="No. Handphone"
                                            id="phone"
                                            maxLength="20"
                                            type="phone"
                                            name="phone"
                                            className={`input-control required ${!validPhone ? `error` : ``}`}
                                            onChange={event => {
                                                var maxlength = event.target.maxLength;
                                                setPhone(event.target.value.slice(0, maxlength));
                                                event.target.value.slice(0, maxlength) === '' ? setValidPhone(false) : setValidPhone(true)
                                            }}
                                            value={phone}
                                            autoComplete="off" />
                                    </div>
                                    <div className={`form-control full textarea required ${!validMessage ? `error` : ``}`} >
                                        <i className="fas text-red fa-font icon-input"></i>
                                        <textarea
                                            id="message"
                                            name="message"
                                            className={`input-control required ${!validMessage ? `error` : ``}`}
                                            autoComplete="off"
                                            maxLength={250}
                                            onChange={event => {
                                                var maxlength = event.target.maxLength;
                                                setMessage(event.target.value.slice(0, maxlength));
                                                event.target.value.slice(0, maxlength) === '' ? setValidMessage(false) : setValidMessage(true)
                                            }} value={message}></textarea>
                                    </div>
                                    <div id="gositusform" className="form-group" style={{ position: 'fixed', top: '-1000px', left: '-1000px' }}>
                                        <input
                                            onChange={event => {
                                                setNameHidden(event.target.value);
                                            }}
                                            id="gositusform" value={nameHidden} maxLength="20" type="text" name="gositusform" className="form-control name" autoComplete="off" />

                                    </div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        size="invisible"
                                    // onChange={captchaChange}
                                    />
                                    {
                                        isSending ?
                                        <button type="button" disabled className="submit-btn my-big-1 bg-blue text-normal button-title disabled mx-auto">Submit <i className="ms-2 fa-solid  fa-right-long"></i></button>
                                            :
                                            <button type="submit" className="submit-btn my-big-1 bg-blue text-normal button-title mx-auto">Submit <i className="ms-2 fa-solid  fa-right-long"></i></button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    <img className="splash-bottom" src={splash_img} />
                </div>
            </motion.div>
            <PreLoader className={`transparent  ${isSending ? "show" : ""}`}>
                <Oval
                    height={60}
                    width={60}
                    color="#df6b48"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#df6b48"
                    strokeWidth={2}
                    strokeWidthSecondary={3}
                />
            </PreLoader>
        </>
    );
}

export default ContactUs;

const FlashMessage = ({ className, text }) => {
    return (
        <div className={`full flashmessage ${className}`}>
            <div>
            {text}
            </div>
        </div>
    )
}