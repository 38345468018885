import { Link } from 'react-router-dom';
import './style.scss';
const ButtonLink = ({ children, link, arrowColor, btnColor, icon }) => {
    return (
        <Link to={link || ''} className='btn-link'>
            <div className={`btn-learn ${btnColor || ''}`}>{children || 'Learn More'}</div>
            <div className={`arrow ${arrowColor || 'Learn More'}`} ><i className={`fa-solid ${icon || 'fa-right-long'}`}></i></div>
        </Link>
    );
}

export default ButtonLink;