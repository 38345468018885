import './style.scss';
const TransitionPage = () => {
    return (
        <>
            <div className="transition-page"></div>
            <div className="transition-page blue-light"></div>
            <div className="transition-page white"></div>
        </>
    );
}

export default TransitionPage;