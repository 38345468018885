import playsafe from '../../../assets/rules/playsafe.svg';
import kids from '../../../assets/rules/kids.svg';
import dress from '../../../assets/rules/dress.svg';
import nosmoking from '../../../assets/rules/no-smoking.svg';
import nocamera from '../../../assets/rules/no-camera.svg';
import images from '../../../assets/rules/image.svg';
import hotline from '../../../assets/rules/hotline.svg';
import noanimal from '../../../assets/rules/no-animals.svg';
import nofood from '../../../assets/rules/no-food.svg';
import {useLanguage} from '../../../lang/LangProvider';

const rulesData = {
    en: [
  
    {
        image: playsafe,
        title: "Play Safely",
        desc: "Please follow the instructions of the lifeguards and comply with Wahoo Waterworld’s rules. For the safety of everyone, please be cautious and considerate of other guests' comfort.",
    },
    {
        image: kids,
        title: "Child Safety",
        desc: "Children must be accompanied by an adult."
    },
    {
        image: dress,
        title: "Proper Attire",
        desc: "Visitors are required to wear appropriate swimwear to ensure comfort, safety, and security."
    },
    {
        image: nocamera,
        title: "Commercial Videos",
        desc: "Any professional video production for commercial purposes must have official permission from Wahoo Waterworld."
    },
    
    {
        image: images,
        title: "Photo Usage Rights",
        desc: "You may be filmed or photographed by Wahoo representatives during your visit. Your entrance ticket to Wahoo Waterworld is considered consent for the use of your image. "
    },
    {
        image: hotline,
        title: "Emergency Hotline",
        desc: "Call +6222 868 088 00 in case of accidents or emergencies."
    },
    {        
        image: nosmoking,
        title: "Smoke-Free Area",
        desc: "Wahoo Waterworld is a designated smoke-free zone. To create a comfortable environment for all guests, smoking is only allowed in designated areas."
    },
    {
        image: noanimal,
        title: "Pets & Hazardous Items",
        desc: "Pets and hazardous items are not allowed inside the park to ensure the safety and comfort of all visitors."
    },
    {
        image: nofood,
        title: "No Outside Food & Beverages",
        desc: "Your belongings will be inspected upon entry to ensure no outside food or beverages are brought into the park."
    },
],
id: [

    {
        image: playsafe,
        title: "bermain dengan aman",
        desc: "Harap ikuti petunjuk dari pengawal renang, dan patuhi peraturan Wahoo Waterworld. Untuk keselamatan semua orang, harap berhati-hati dan jaga kenyamanan tamu lain.",
    },
    {
        image: kids,
        title: "keselamatan anak-anak",
        desc: "Anak-anak harus didampingi orang dewasa."
    },
    {
        image: dress,
        title: "berpakaian yang tepat",
        desc: "Pengunjung harus memakai pakaian renang yang sesuai untuk menjaga kenyamanan, keselamatan, dan keamanan."
    },
    {
        image: nocamera,
        title: "video komersil",
        desc: "Setiap pembuatan video profesional untuk keperluan komersil harus mendapatkan izin resmi dari Wahoo Waterworld."
    },
    {
        image: images,
        title: "hak pakai foto",
        desc: "Kamu bisa disorot, direkam, atau difoto oleh representatif taman saat berkunjung. Tiket masuk kamu ke Wahoo Waterworld dianggap sebagai persetujuan untuk memakai dokumentasi Anda. "
    },
    {
        image: hotline,
        title: "saluran telepon darurat",
        desc: "Telepon +6222 868 088 00 jika ada kecelakaan atau keadaan darurat."
    },
    {
        image: nosmoking,
        title: "bebas asap",
        desc: "Wahoo Waterworld adalah Kawasan Terbatas Merokok. Untuk menciptakan lingkungan yang nyaman bagi semua pengunjung, merokok hanya diperbolehkan di area khusus."
    },
    {
        image: noanimal,
        title: "hewan peliharaan & alat-alat berbahaya",
        desc: "Hewan peliharaan dan alat-alat berbahaya tidak diizinkan di dalam taman untuk menjaga keamanan dan kenyamanan pengunjung."
    },
    {
        image: nofood,
        title: "dilarang membawa makanan & minuman dari luar",
        desc: "Barang bawaan kamu akan diperiksa pada saat masuk untuk memastikan tidak ada makanan dan minuman dari luar."
    },
],
};

const RulesData = () => {
    const { currentLanguage } = useLanguage();

    const selectedRules = rulesData[currentLanguage] || rulesData['id'];
  
    return selectedRules;
  };
  
export default RulesData;
  