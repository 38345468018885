import './style.scss';
const PreLoader = ({children, className}) => {
    return ( 
        <>
            <div className={`loading__wrap ${className}`}>
                {children}
            </div>
        </>
     );
}
 
export default PreLoader;