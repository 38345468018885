import './style.scss';
const ImageClip = ({ src, alt, children }) => {
  return (
    <div className='imageclip' style={{ position: 'relative', width: '100%', height: '300px', overflow: 'hidden' }}> 
      <img loading="lazy" src={src} alt={alt || ''} style={{ position: 'relative', width: '100%', height: '300px', overflow: 'hidden' }} />
      <svg width="0" height="0">
        <clipPath id="svgClip" clipPathUnits="objectBoundingBox">
          <path d="m 0.892 0.092 C 1.016 0.226 1.021 0.792 0.877 0.888 C 0.683 1.027 0.273 0.991 0.139 0.891 S -0.049 0.247 0.128 0.094 c 0.156 -0.108 0.541 -0.111 0.739 -0.017 Z"></path>
        </clipPath>
      </svg>
      {children || ''}
    </div>
  );
};

export default ImageClip;
