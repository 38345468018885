import { useState, useEffect } from "react";
import DiningList from "../../components/ThingsToDo/DiningList";
import waveTop from '../../components/TicketCircularSlider/wave-top.svg';
import splash from "../../components/TicketCircularSlider/splash.png";
import BannerImage from "../../components/BannerImage";
import ModalService from "../../components/ModalService";
import Popup from 'reactjs-popup';
import { motion } from "framer-motion";
import useFetch from "../../customFunction/useFetch";
import FirstBanner from "../../components/FirstBanner";
import {useLanguage} from '../../lang/LangProvider';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}

const Services = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const {translate,currentLanguage} = useLanguage();

    document.title = "Service & Facilites | Wahoo Waterworld";
    // const handleToggle = useCallback(() => setShowModal(showModal => !showModal), [])
    const byModal = true;
    const { data: dataBanner, isPending: isPendingBanner, error: errorBanner } = useFetch(apiUrl + 'banner?section=3');
    const { data: dataService, isPending: isPendingService, error: errorService } = useFetch(apiUrl + 'service?lang='+currentLanguage);
    const { data: dataFacilites, isPending: isPendingFacilites, error: errorFacilites } = useFetch(apiUrl + 'service?type=facilites&lang='+currentLanguage);
    const closeModal = () => setShowModal(false);
    const handleClick = () => {
        setShowModal(!showModal);
    };
    const handleData = (data) => {
        setModalData(data);
    }
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <>
            <motion.div className="position-relative"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                {/* <BannerImage image={dataBanner && dataBanner.length > 0 ? baseUrl+dataBanner[0].image_url : null} title="Services & Facilites" wave="yes" /> */}
                <FirstBanner color1="yellow">
                    <div className="button-title mx-auto mb-2">Services & Facilites</div>
                    {/* <h2 className="secondary-title text-center text-white">Variasi tempat makan yang menyajikan makanan lezat & minuman segar!</h2> */}
                </FirstBanner>
                <div className="section pb-0 pt-1">
                    <div className="bg-yellow button-title mx-auto mb-5 text-medium">Services</div>
                    <div className="container">
                        {isPendingService && ' '}
                        {errorService && errorService.message}
                        {/* {dataService && <DiningList className='services-page no-center' handleData={handleData} handleClick={handleClick} modal={byModal} dinings={dataService} />}                         */}
                        {dataService && <DiningList className='services-page no-center' noPointer={true} handleData={handleData}  modal={byModal} dinings={dataService} />}
                    </div>
                </div>
                <div className={`section yellow pt-0 min-125 ${isPendingFacilites && 'd-none'}`} >
                    <div className="ticket-circular-slider-section">
                        <div className="wave wave-top mb-6">
                            <img className="img-wave" src={waveTop} alt="" />
                            <div className="ornaments">
                                <div className="right splash">
                                    <img src={splash} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-title mx-auto mb-2 text-medium">Faciliites</div>
                    <div className="container">
                        <h2 className="third-title col-10 mb-5 pb-5 mx-auto text-center">{translate('service.fasilitas_sub_heading')}</h2>
                        {isPendingFacilites && ' '}
                        {errorService && errorService.message}
                        {/* {dataFacilites && <DiningList className='services-page no-center' handleData={handleData} handleClick={handleClick} modal={byModal} dinings={dataFacilites} />}                         */}
                        {dataFacilites && <DiningList className='services-page no-center' noPointer={true} handleData={handleData}  modal={byModal} dinings={dataFacilites} />}
                    </div>
                </div>
            </motion.div>
            <Popup open={showModal} closeOnDocumentClick onClose={closeModal} position="center" modal={true} >
                <ModalService data={modalData && modalData} handleClick={handleClick} />
            </Popup>
            {/* {showModal && <ModalService handleClick={handleClick} />} */}
        </>
    );
}
export default Services;