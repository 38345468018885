const en = {
        "welcome": "Welcome",
        "login": "Login",
        "home":{
           "seluncuran_air":`<span class="white anim">
                            Southeast Asia's
                        </span>
                        <span class="orange anim">
                            fastest and first
                        </span>
                        <span class="blue anim">water slide</span>`,
            "petualangan": `
                            <span class="white anim">An exciting water adventure</span>
                            <span class="blue anim"> for the whole family</span>
                            `,
            "temukan_petualangan":"Discover an exciting adventure with amazing rides and attractions",
            "min_tinggi":"Min. Height",
            "no_tinggi":"No. min height",
            "boomerango_desc":"Boomerango takes you through a dramatic tunnel of light rings, and straight onto a massive skateboard with a near-vertical launch. Enjoy the thrilling excitement of sliding with screams of joy.",
            "beli_langsung":"Buy directly and get the best prices!",
            "tiket":'Tickets'
        },
        "getting_here":{
            "sub_heading":"To get to Wahoo Waterworld",
            "jam_buka": 'Opening Hours'
          },
          "group_booking":{
            "sub_heading":"Wahoo Waterworld is the perfect venue for a special event, birthday, family outing or a group of friends looking to have some fun! Our team can help you with your enquiries and let you know our special rates and packages for groups over 50 people."
            ,"nama":"Name",
            "phone":"Phone",
            "event":"Select Event",
            "submit":"Submit"  
        },
        "plan_your_event":{
            "tarif_khusus":"Get our special rates and packages for groups of more than 50 people, Click the button on the Right"
        },
        "accomodation":{
            "sub_heading":"Visitors from outside Bandung can choose Mason Pine Hotel Bandung for a classy and comfortable accommodation, a hotel surrounded by beautiful natural scenery."
        },
        "dining":{
            "sub_heading":"Variety of eating places serving delicious food & fresh drinks!"
        },
        "tips":{
            "sub_heading":"The most enjoyable recreation is the one that's well-planned! Check out these useful tips to ensure a smooth and safe experience at Wahoo Waterworld."
        },
        "event_venue":{
            "sub_heading":"Enjoy Exciting Adventures in Every Celebration & Activity!"
        },
        "map":{
            'title':'Site Map',
            'sub_heading':"Discover the fun at Wahoo Waterworld effortlessly!"
        },
        "service":{
            "fasilitas_sub_heading":"Various kinds of comfortable and clean facilities!"
        }

}
export default en;
