import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
const NavClass = ({ link, name, handleCurrentLink }) => {
    const location = useLocation().pathname;
    const className = (location === link) ? "link-active" : '';
    return (
        <>
            <Link onClick={() => handleCurrentLink && handleCurrentLink(link)} className={className} to={link}>{name}</Link>
        </>
    );
}

export default NavClass;