import MainSlider from "../components/MainSlider";
import BottomWave from "../components/ui/BottomWave";
import RideCircularSlider from "../components/RideCircularSlider";
import TicketCircularSlider from "../components/TicketCircularSlider";
import PromotionSlider from "../components/PromotionSlider";
import InstagramFeed from "../components/InstagramFeed";
import TrailerVideo from "../components/TrailerVideo";
import waterSplash from "../assets/splash.webp";
import octo from "../assets/gurita.png";
// import imgVideo from "../assets/from-top.jpg";
import imgVideo from "../assets/video-cover.jpg";
// import SliderSection from "../components/SliderSection";
import Fader from "../components/Fader";
import useFetch from "../customFunction/useFetch";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import {useLanguage} from '../lang/LangProvider';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}

const Home = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { currentLanguage} = useLanguage();

    const { data: dataBanner, isPending: isPendingBanner, error: errorBanner } = useFetch(apiUrl + 'banner');
    const { data: dataRide, isPending: isPendingRide, error: errorRide } = useFetch(apiUrl + 'ride_attraction?lang='+currentLanguage);
    const { data: dataTicket, isPending: isPendingTicket, error: errorTicket } = useFetch(apiUrl + 'ticket');
    const { data: dataPromo, isPending: isPendingPromo, error: errorPromo } = useFetch(apiUrl + 'promo');
    const { data: dataContact } = useFetch(apiUrl + 'contact');
    const { data: dataMetadata } = useFetch(apiUrl + 'metadata');

    const [playAnimation, setPlayAnimation] = useState(true);
    const { data: dataPopup, isPending: isPendingPopup, error: errorPopup } = useFetch(apiUrl + 'banner?section=4');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setOpen(false);
        var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
        Cookies.set('dismiss', 'yes', {
            expires: inFifteenMinutes
        });
    }
    document.title = `Wahoo Waterworld | The Biggest Waterpark in West Java`
    if (dataMetadata && dataMetadata.web_title) {
        document.title = `${dataMetadata.web_title}`
    }
    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(false)
        }
        setTimeout(() => {
            setOpen(Cookies.get('dismiss') && Cookies.get('dismiss') == 'yes' ? false : true)
        }, 5500);
        if (document.readyState === 'complete') {
            onPageLoad();
        }
        else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad)
        }
    }, []);
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {dataBanner && <MainSlider data={dataBanner} />}
            {/* <MainSlider /> */}
            <div className="section yellow overflow-hidden" style={{ paddingTop: '50px' }}>
                <div className="container">
                    <div className="big-heading">
                        <div className="sub-title">Wahoo Waterworld</div>
                        {/* <SliderSection /> */}
                        <Fader />
                    </div>
                </div>
                <div className="ornaments">
                    <div className="left">
                        <img src={waterSplash} alt="" />
                    </div>
                    <div className="right">
                        <img src={octo} alt="" className="float-anim" />
                    </div>
                </div>
                <BottomWave />
            </div>
            <div className="section overflow-hidden pb-0 pt-0">
                {dataRide && <RideCircularSlider attractions={dataRide} />}
            </div>
            <div className="section no-padding yellow overflow-hidden">
                {dataTicket && <TicketCircularSlider data={dataTicket} />}
            </div>
            {/* {dataPromo && <PromotionSlider data={dataPromo} />} */}
            {/* <div className="section">
                <InstagramFeed />
            </div> */}
            <div className="min-125">
            {dataContact && <TrailerVideo url={dataContact.youtube} placeholder={imgVideo} mask={true}/>}
                {/* <TrailerVideo url='https://youtu.be/FMf4Q8hZO2M' placeholder={imgVideo} mask={true} /> */}
            </div>
            {dataPopup && dataPopup.length > 0 &&
                <Popup className="popupmodal" open={open} closeOnDocumentClick onClose={closeModal}>
                    <div className="close" onClick={closeModal}>
                        <i className="fa fa-times"></i>
                    </div>
                    <Link to={dataPopup[0].url}>
                        <img className="dekstop-only" src={baseUrl + dataPopup[0].image_url} alt={dataPopup[0].name} />
                        <img className="mobile-only" src={baseUrl + dataPopup[0].image_potrait_url} alt={dataPopup[0].name} />
                    </Link>
                </Popup>
            }
        </motion.div>
    );
}

export default Home;