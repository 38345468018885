import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import FirstBanner from '../components/FirstBanner';
import {useLanguage} from '../lang/LangProvider';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.35, duration: 0.5 }
  },
  exit: {
    y: -30,
    opacity: 0,
    transition: { duration: 0.35 }
  }
}

const TermAndCondition = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [termData, setTermData] = useState(null);
  const { currentLanguage} = useLanguage();

  useEffect(() => {
    const fetchTermData = async () => {
      try {
        const response = await fetch(apiUrl + 'page?section=5&lang='+currentLanguage);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const termData = await response.json();
        setTermData(termData);
      } catch (error) {
        console.error('Error fetching term data:', error);
      }
    };

    fetchTermData();
  }, [apiUrl,currentLanguage]);

  return (
    <motion.div
      className="position-relative"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
       <FirstBanner color1="lime">
        <div className="button-title" dangerouslySetInnerHTML={{ __html: termData ? termData.heading : "T&C" }} />
        </FirstBanner>
      <div className="mbl-px-0 container">
        <div className="syaratcondition">
          {termData ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: termData.content }} />
            </>
          ) : (
            <p>No Content...</p>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default TermAndCondition;
