import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCreative, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import parse from 'html-react-parser';
import "./style.scss";
const CardSlider = ({ data }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const config = {
        autoplay: false,
        spaceBetween: 30,
        slidesPerView: 1,
        // loop: false,
        speed: 500,
        pagination: false,
        navigation: true,
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        breakpoints: {
            460: {
                slidesPerView: 2,
                // spaceBetween: 30
            },
            991: {
                slidesPerView: 4,
                // spaceBetween: 30
            },
        },
        modules: [Pagination, Navigation, EffectCreative, Autoplay],
    };
    return (
        <Swiper {...config} className="card-slider mb-big-4">
            {
                data.map((datanews, index) => (
                    <SwiperSlide key={datanews.id}>
                        <Link to={`../article/${datanews.seo_url}`}>
                            <div className="card-article">
                                <div className="div-img">
                                    <img src={baseUrl+'lib/images/news/'+datanews.image} alt={datanews.name} />
                                </div>
                                <div className="div-content">
                                    <p className="card-title">{datanews.name}</p>
                                    <div className="card-p">{parse(datanews.content)}</div>
                                </div>
                            </div>
                        </Link>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    );
}

export default CardSlider;