import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
const Seo = ({ data }) => {
    return (
        <Helmet>
            {/* <title>Wahoo Waterworld - Asia's First All-weather Water Park</title> */}

            <meta name="description" content={data.description} />
            <meta name="Language" content={data.language} />
            <meta name="keywords" content={data.keyword} />
            <meta name="theme-color" content={data.theme_color} />
            <meta name="msapplication-TileColor" content={data.theme_color} />
            <meta property="og:title" content={data.web_title} />
            <meta property="og:description" content={data.description} />
            <meta property="og:image" content={data.logo} />
            <meta property="og:locale" content={data.language_attr} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.wahoowaterworld.co.id/" />
            <meta property="og:site_name" content={data.site_name} />
            <meta property="og:image:secure_url" content={data.logo} />
            {/* <link rel="icon" type="image/png" sizes="16x16" href={data.icon16} /> 
            <link rel="icon" type="image/png" sizes="32x32" href={data.icon32} />
            <link rel="icon" type="image/png" sizes="192x192" href={data.icon192} />
            <link rel="shortcut icon" type="image/x-icon" href={data.ico} />
            <link rel="mask-icon" href={data.svg} color={data.theme_color} />
            <link rel="apple-touch-icon" type="image/png" sizes="180x180" href={data.apple180} />
            <link rel="apple-touch-icon" type="image/png" sizes="512x512" href={data.apple512} /> */}
        </Helmet>
    );
}

export default Seo;