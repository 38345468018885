import splash_img from "../assets/splash.webp";
import FirstBanner from "../components/FirstBanner";
import TicketList from "../components/TicketList";
import ticketDummy from '../assets/ticket/ticket-1.png';
import ticketLogo from '../assets/ticket/ic-ticket.svg';
import useFetch from "../customFunction/useFetch";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ButtonBubble from "../components/ui/ButtonBubble";
import React, { useState } from 'react';
// import termModal from "../components/ModalTerm";
import {useLanguage } from '../lang/LangProvider';

import Popup from 'reactjs-popup';
const containerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.35, duration: 0.5 }
    },
    exit: {
        y: -30,
        opacity: 0,
        transition: { duration: 0.35 }
    }
}
const TicketPass = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { currentLanguage } = useLanguage();

    const { data, isPending, error } = useFetch(apiUrl + 'ticket?lang='+currentLanguage);
    const { data: dataTerm, isPending: isPendingTerm, error: errorTerm } = useFetch(apiUrl + 'page?section=5&lang='+currentLanguage);
    document.title = "Ticket & Package | Wahoo Waterworld";

    const [isActive, setIsActive] = useState(false);
    const [errorTermCheck, setErrorTerm] = useState(false);

    const termClick = () => {
        setIsActive(!isActive);
        setErrorTerm(false);
    };

    const ticketDisableClick = () => {
        setErrorTerm(true);
    };

    const termClass = isActive ? 'active' : '';

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  

    return (
        <motion.div className="position-relative"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <FirstBanner color1="blue" color2="lightblue">
                <div className="button-title bg-yellow" >Ticket Pass</div>
            </FirstBanner>
            <div className="py-big-3 pb-big-8">
                <div className="container">
                    {isPending && ' '}
                    {error && error.message}
                    {data && <TicketList data={data} />}
                </div>
                
                <div className="container flex-center   ">
                    <div className="d-flex term-content mb-4">
                    <label htmlFor="cbtest" className={`checkbox ${termClass}`} onClick={termClick}></label>
                    <label htmlFor="term" className="text-left" > <span onClick={termClick}>Mohon pastikan Anda memahami dan menerima</span> <b className="popup-click" onClick={handleOpenModal}>syarat dan ketentuan</b> <span onClick={termClick}>pembelian Tiket. Semua pembelian Tiket tidak dapat dikembalikan.</span></label>
                    </div>
                </div>
                <div className="flex-center">
                    {isActive ? ( 
                  
                  <Link to="/booking">
                  <ButtonBubble className="ticket-button">
                      <div className="icon">
                          <img src={ticketLogo} alt="" />
                      </div>
                      <div className="button-text">Buy Tickets</div>
                  </ButtonBubble>
                  </Link>
                  ) : (
                    <span onClick={ticketDisableClick}>
                    <ButtonBubble className="ticket-button disable">
                        <div className="icon">
                            <img src={ticketLogo} alt="" />
                        </div>
                        <div className="button-text">Buy Tickets</div>
                    </ButtonBubble>
                </span>
                    )
                    }
                   
                </div>
                <div className="text-center mt-5">
                {errorTermCheck ?  (
                    <label className="d-block mb-2 error">Setujui syarat & ketentuan sebelum melanjutkan</label>
                ):('')}
                    <i className="d-block">*Harga dapat berubah sewaktu - waktu tanpa pemberitahuan terlebih dahulu</i>
                </div>
            </div>
            <img className="splash-bottom" alt="splash" src={splash_img} />
            {showModal && (
        <div className="modals">
        <div className="position-relative">
            <button className='close-modal' onClick={handleCloseModal}><i className="fas fa-times"></i></button>
            <div className="container">
                <div className="modal-content">
                    <div className="list-item ">
                        
                        <div className="content-list">
                             
                        <div className="flex-center">
                            <h2 className="mb-4">{dataTerm && (<div dangerouslySetInnerHTML={{ __html: dataTerm['heading'] }} />)}</h2>
                            </div>

                        {dataTerm && (<div dangerouslySetInnerHTML={{ __html: dataTerm['content'] }} />)}
                        </div>
                    </div>
                </div>
                <div className="d-mbl-none bg-yellow aksen modal-content">
                </div>
            </div>
            <svg width="0" height="0">
                <clipPath id="svgClip" clipPathUnits="objectBoundingBox">
                    <path d="m 0.892 0.092 C 1.016 0.226 1.021 0.792 0.877 0.888 C 0.683 1.027 0.273 0.991 0.139 0.891 S -0.049 0.247 0.128 0.094 c 0.156 -0.108 0.541 -0.111 0.739 -0.017 Z"></path>
                </clipPath>
            </svg>
        </div>
    </div>
      )}
        </motion.div>
    );
}

export default TicketPass;