export default function ImageCard({ image }) {
    return (
        <svg viewBox="0 0 356 453" fill="none">
            <defs>
                <clipPath id="shape">
                    <path xmlns="http://www.w3.org/2000/svg" d="M0 386.451V77.0282C0 14.803 35.025 0 178 0C313.264 0 356 8.90802 356 87.3773C356 87.3773 356 312.436 356 376.626C356 440.816 304.9 452.999 178 452.999C41.9515 453.13 0 430.467 0 386.451Z" fill="#D9D9D9" />
                </clipPath>
            </defs>
            <image
                clipPath="url(#shape)"
                xlinkHref={image}
            ></image>
        </svg>
    );
}