const menus = [
    {
        name: "Things To Do",
        id: 1,
        children: [
            // {
            //     name: "What's On",
            //     link: "/whatson",
            // menu_id: 1
            // },
            {
                name: "Dining",
                link: "/dining",
                menu_id: 1
            },
            {
                name: "Service & Facilities",
                link: "/services",
                menu_id: 1
            },
            {
                name: "Event Venue",
                link: "/event-venue",
                menu_id: 1
            },
        ],
    },
    {
        name: "Plan Your Visit",
        id: 2,
        children: [
            {
                name: "Getting Here",
                link: "/getting-here",
                menu_id: 2
            },
            {
                name: "Map",
                link: "/map",
                menu_id: 2
            },
            {
                name: "Tips",
                link: "/tips-on-wahoo",
                menu_id: 2
            },
           
            {
                name: "Plan Your Event",
                link: "/plan-your-event",
                menu_id: 2
            },
            {
                name: "Accomodation",
                link: "/accomodation",
                menu_id: 2
            },
            {
                name: "Term and Condition",
                link: "/term-and-condition",
                menu_id: 2
            },
        ],
    },
    {
        name: "Ride and Attractions",
        id: 3,
        children: [
            {
                name: "Abyss",
                link: "/ride-attraction/abyss",
                menu_id: 3
            },
            {
                name: "The Caterpillar",
                link: "/ride-attraction/the-caterpillar",
                menu_id: 3
            },
            {
                name: "RocketBLAST",
                link: "/ride-attraction/rocketblast",
                menu_id: 3
            },
            {
                name: "Boomerango",
                link: "/ride-attraction/boomerango",
                menu_id: 3
            },
            {
                name: "Super Bowl",
                link: "/ride-attraction/super-bowl",
                menu_id: 3
            },
            {
                name: "Wave Pool",
                link: "/ride-attraction/wave-pool",
                menu_id: 3
            },
            {
                name: "Splash Zone",
                link: "/ride-attraction/splash-zone",
                menu_id: 3
            },
            {
                name: "Aqua Zone",
                link: "/ride-attraction/aqua-zone",
                menu_id: 3
            },
            {
                name: "RainFortress",
                link: "/ride-attraction/rainfortress",
                menu_id: 3
            },
            {
                name: "Play Zone",
                link: "/ride-attraction/play-zone",
                menu_id: 3
            },
            {
                name: "Lazy River",
                link: "/ride-attraction/lazy-river",
                menu_id: 3
            },
            {
                name: "Warm Pool",
                link: "/ride-attraction/warm-pool",
                menu_id: 3
            },
            {
                name: "Lagoon Pool",
                link: "/ride-attraction/lagoon-pool",
                menu_id: 3
            },
            // {
            //     name: "Lagoon Pool",
            //     link: "/ride-attraction/lagoon-pool",
            // },
        ],
    },
    {
        name: "Tickets & Package",
        link: "/ticket-and-package",
        id: 4
    },
];

export default menus;