import { useEffect, useRef, useState } from 'react';
import ButtonBubble from "../ButtonBubble"
import "./style.scss";
import logo from "../../../assets/wahoo-logo.png";
import ticket_icon from "./ic-ticket.svg";
import list_menu from "./menus"
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import { Link, useLocation } from 'react-router-dom';
import LanguageSwitcher from '../../../lang/LangSwitcher';
export default function Header() {
    const [onTop, setOnTop] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const [parent, setParent] = useState('');
    const [burgerOpen, setBurgerOpen] = useState(false);
    const [burgerSubOpen, setBurgerSubOpen] = useState(false);
    const [mobileActiveMenu, setMobileActiveMenu] = useState();
    const location = useLocation().pathname;
    const headerRef = useRef();
    const prevScrollpos = useRef(0);
    const [weatherData, setWeatherData] = useState(null);
    const [weatherContent, setWeatherContent] = useState();
  
    useEffect(() => {
        const apiKey = "7f7ecc63958c4081b3791449230211";
        const apiUrl = `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=-6.79759888681063,107.45120194272658`;
    
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => setWeatherData(data));
      }, []);

    //   if (!weatherData) {
    //     setWeatherContent("<div>Loading...</div>") ;
    //   }else{
    //     setWeatherContent("<div><h1>Weather in {weatherData.location.name}</h1><p>Temperature: {weatherData.current.temp_c}°C</p><p>Condition: {weatherData.current.condition.text}</p></div>") ;
        
    //   }
    

    useEffect(() => {
        // headerRef.current.className = "initialized";
        function showChild(event) {
            const child = event.target.querySelector('.child-menu');
            event.target.classList.add('showing');
            child.classList.add('animated')
        }
        function hideChild(event) {
            const child = event.target.querySelector('.child-menu');
            event.target.classList.remove('showing');
            child.classList.remove('animated')
        }
        function hideChildOnShow(event) {
            const parent = event.target.closest('.child-menu');
            const allMenus = document.querySelectorAll('.menus .single-menu.has-child');
            allMenus.forEach((el) => el.classList.remove('showing'));
            parent.classList.remove('animated')
        }
        const menus = document.querySelectorAll('.menus .single-menu.has-child');
        menus.forEach((el) => el.addEventListener('mouseenter', showChild));
        menus.forEach((el) => el.addEventListener('mouseleave', hideChild));

        const childMenus = document.querySelectorAll('.menus .single-menu.has-child .child-menu-container a');
        childMenus.forEach((el) => el.addEventListener('click', hideChildOnShow));


        return () => {
            menus.forEach((el) => el.removeEventListener('mouseenter', showChild));
            childMenus.forEach((el) => el.removeEventListener('click', showChild));
        }
    }, [])
    useEffect(() => {
        function listener() {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollpos.current > currentScrollPos) {
                // scrolling up
                setShowMenu(true);
            } else {
                if (currentScrollPos > 100) {
                    setShowMenu(false);
                }
            }
            if (currentScrollPos < 10) {
                setOnTop(true);
            } else {
                setOnTop(false);
            }
            prevScrollpos.current = currentScrollPos;
        }
        window.addEventListener('scroll', listener);


        return () => {
            window.removeEventListener('scroll', listener);
        }
    }, [])
    function toggleMenu() {
        setBurgerOpen(state => !state);
    }
    function openSubMenu(children) {
        setMobileActiveMenu(() => children);
        setBurgerSubOpen(true);
        var parent = children[0].menu_id;
        const list = list_menu.filter((item) => {
            return item.id == parent;
        })
        setParent(list[0].name);
    }
    function closeSubMenu() {
        setBurgerSubOpen(() => false);
        setMobileActiveMenu(() => null);
    }
    const headerClasses = [];
    if (showMenu) headerClasses.push('show');
    if (onTop) headerClasses.push("on-top");
    const burgerMenuClasses = ['mobile-menu'];
    if (burgerOpen) burgerMenuClasses.push('open');
    if (burgerSubOpen) burgerMenuClasses.push('subopen');
    
    return (
        <header id='header' ref={headerRef} className={headerClasses.join(" ")}>
            <div className="bg-white">
                <div className="container"style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="logo">
                    <Link to="/"><img className="logo-img" src={logo} alt="Wahoo Waterpark" /></Link>
                      <LanguageSwitcher/>
                        {weatherData && (
                            <div className="weather">
                            <img src={weatherData.current.condition.icon} className="icon" alt="icon-weather"/>
                            <div className="condition">
                                <span>{weatherData.current.condition.text}</span>
                                <span>{weatherData.current.temp_c}°C</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="menu-section">
                <div className="container">
                    <div className="menus">
                        {list_menu.map((el, idx) => <Menu key={'menu' + idx} children={el.children} name={el.name} link={el.link} />)}
                        <Link to="/ticket-and-package">
                            <div className='single-menu rotate'>
                                <ButtonBubble className="ticket-button">
                                    <div className="icon">
                                        <img src={ticket_icon} alt="" />
                                    </div>
                                    <div className="button-text">Buy Tickets</div>
                                </ButtonBubble>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="curved-end"></div>
            <div className={burgerMenuClasses.join(" ")}>
                <div className="bubble menu-bubble"></div>
                <div className="bubble menu-bubble top-bubble"> </div>
                <div className={burgerSubOpen ? 'bubble submenu-bubble open' : 'bubble submenu-bubble'}></div>
                <div className="menus">
                    {list_menu.map((el, idx) => <MobileMenu name={el.name} closeSubMenu={closeSubMenu} link={el.link} idMenu={el.id} children={el.children} toggleMenu={toggleMenu} openSubMenu={openSubMenu} key={'mobile-menu' + idx} />)}
                    <div className="single-menu">
                        <Link onClick={() => {setBurgerOpen(false)}} to="/ticket-and-package">
                            <ButtonBubble className="ticket-button">
                                <div className="icon">
                                    <img src={ticket_icon} alt="" />
                                </div>
                                <div className="button-text">Buy Tickets</div>
                            </ButtonBubble>
                        </Link>
                    </div>
                </div>
                <div className={burgerSubOpen ? 'submenu open' : 'submenu'}>
                    {burgerSubOpen && 
                        <>
                            <div className='parent__menu'>
                                <h2>{parent}</h2>
                            </div>
                        </>
                    }
                    {mobileActiveMenu && mobileActiveMenu.map((el, idx) => <MobileMenu closeSubMenu={closeSubMenu} burgerSubOpen={burgerSubOpen} toggleMenu={toggleMenu} name={el.name} link={el.link} key={"mobile-submenu-" + el.name} />)}
                </div>
                <div className="menu-button" onClick={burgerSubOpen ? closeSubMenu : toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </header>
    );
}