import ImageClip from "../ui/ImageClip";
const ListDiv = ({ data }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <>{

            data.map((datalist, index) => (
                <div key={datalist.id} className="merchandise-list mb-big-2">
                    <ImageClip src={baseUrl+datalist.image_url} />
                    <h4 className="secondary-title text-blue my-2">{datalist.name}</h4>
                    <div dangerouslySetInnerHTML={{
                        __html: datalist.description
                    }}></div>
                </div>
            ))
        }
        </>
    );
}

export default ListDiv;