import "./style.scss";
import ImageClip from "../../ui/ImageClip";
import ButtonLink from "../../ui/ButtonLink";
import parse from 'html-react-parser';
import { useRef, useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

const  DiningList = ({ dinings, className, showButtonLink, handleClick, handleData, imgLink, noPointer }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <>
      <div className={`dinning-page ${className || ''}`}>
        {dinings.map((dining) => (
          <div key={dining.id} className="dining-div pb-6 mb-6 px-4">
            <div className={`dining-img  ${noPointer ? 'no-pointer' : ''}`}onClick={() => { handleClick && handleClick(); handleData && handleData(dining) }} >
              {
                imgLink ?
                  <>
                    <Link to={dining.seo_url}>
                      <ImageClip src={baseUrl + dining.image_url} alt={dining.name} />
                    </Link>
                  </>
                  : <ImageClip src={baseUrl + dining.image_url} alt={dining.name} />
              }
            </div>
            <div className="dining-list">
              {
                dining.name && <div className={`button-title mb-3 text-normal ${className === 'services-page' || className === 'plan-page' ? 'bg-lightblue' : 'bg-yellow'}`}>{dining.name}</div>
              }
              <div className={`mb-0 ${className === 'services-page' ? 'mt-4' : ''}`}>
                {parse(dining.description)}
              </div>
              {showButtonLink &&
                <div className="link-center bigger">
                  <ButtonLink arrowColor="bg-orange" link={dining.seo_url} />
                </div>
              }
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default DiningList;
