import swimming from '../../../assets/tips/swimming-suit.svg';
import sunblock from '../../../assets/tips/sunblock.svg';
import lifeguard from '../../../assets/tips/lifeguard.svg';
import sunrise from '../../../assets/tips/sunrise.svg';
import hungry from '../../../assets/tips/hungry.svg';
import lifejacket from '../../../assets/tips/lifejacket.svg';
import team from '../../../assets/tips/team.svg';
import cashless from '../../../assets/tips/cashless.svg';
import safe_deposit from '../../../assets/tips/safe-deposit.svg';
import measure from '../../../assets/tips/measure.svg';
import {useLanguage} from '../../../lang/LangProvider';


const tipsData = {
    id:[
    {
        image: swimming,
        title: "ATURAN BERPAKAIAN",
        desc: "Untuk menjaga kenyamanan semua orang, kami meminta pengunjung agar berpakaian sepantasnya di tempat rekreasi ramah keluarga Wahoo Waterworld.<br></br>Semua pengunjung diwajibkan memakai pakaian berenang yang sesuai saat menggunakan semua wahana di Wahoo Waterworld.<br></br> Wahoo Waterworld berhak melarang masuk atau mengeluarkan tamu yang tidak memakai pakaian yang sesuai dengan aturan berpakaian Wahoo Waterworld, atau memakai pakaian yang membahayakan keselamatan, mengganggu kenyamanan tamu lain, atau tidak sesuai dengan kode berpakaian Wahoo Waterworld. Tamu yang memerlukan pengaturan khusus wajib memberitahu tim manajemen keselamatan umum saat tiba di Wahoo Waterworld.",
        //  title: "DRESS CODE",
        // desc: "To ensure comfort for everyone, all guests must wear swimsuits when using the rides at Wahoo Waterworld. Additionally, infants and toddlers must wear swimming diapers. Guests are advised to bring a laundry bag for worn / dirty clothes.",
    },
    {
        image: sunblock,
        title: "AMAN DI BAWAH MATAHARI",
        desc: " Tetap nyaman sambil berjemur dengan memakai tabir surya dan membawa kacamata hitam.",
        //  title: "SAFE IN THE SUN",
        // desc: "Stay safe while you're having fun in the sun by wearing sunblock or sunscreen, and bringing your sunglasses."
    },
    {
        image: lifeguard,
        title: "PATUHI PERATURAN",
        desc: "Untuk menjaga keselamatan, ikuti petunjuk dari pengawal renang dan patuhi peraturan Wahoo Waterworld."
        //   title: "LOOK FOR THE LIFEGUARD",
        // desc: "For safety and security, please follow the lifeguard's instructions and comply to the Wahoo Waterworld's rules and regulation."
    },
    {
        image: sunrise,
        title: "DATANG AWAL",
        desc: "Wahoo Waterworld dibuka jam 9 pagi, jadi pastikan kamu datang lebih awal supaya bisa menikmati hari yang menyenangkan lebih lama."
        //   title: "GET THERE EARLY",
        // desc: "Early bird gets the worm! The park is open at 9am, so make sure to come early to make the most of your fun day at Wahoo Waterworld."
    },
    {
        image: hungry,
        title: "MERASA LAPAR?",
        desc: "Wahoo Waterworld menyediakan outlet makanan dan minuman untuk mengatasi rasa lapar dan haus. Laguna Café menawarkan berbagai hidangan Indonesia, Asia dan barat, sementara Odie's Food Street menghidangkan jajanan khas lokal dan Asia. Kamu juga bisa mendapatkan camilan ringan, berbagai minuman dan es krim di Oz's Snacks. ",
        //   title: "FEELING HUNGRY?",
        // desc: "Wahoo Waterworld offers F&B outlets to satiate your hunger and quench your thirst. Laguna Café serves variety of Indonesian, Asian and western dishes, while Odie's Food Street carries local and Asian street food selection. You can also find light snacks, various beverages and ice cream at Oz's Snacks. "
    },
    {
        image: lifejacket,
        title: "HANDUK & JAKET PELAMPUNG",
        desc: "Harap membawa handuk.  Disarankan membawa jaket pelampung (jika diperlukan)."
        //   title: "TOWELS & LIFE VESTS",
        // desc: "Please bring your own towels and life vests. Additionally, we also provide towels and life vests for rent."
    },
    {
        image: team,
        title: "TANYA TIM KAMI",
        desc: "Silahkan datangi anggota staf Wahoo Waterworld yang ramah dan berseragam di seputar taman apabila ada pertanyaan atau membutuhkan bantuan."
        //   title: "ASK OUR TEAM",
        // desc: "Wahoo Waterworld offers F&B outlets to satiate your hunger and quench your thirst. Laguna Café serves variety of Indonesian, Asian and western dishes, while Odie's Food Street carries local and Asian street food selection. You can also find light snacks, various beverages and ice cream at Oz's Snacks."
    },
    {
        image: cashless,
        title: "KEMUDAHAN NIRTUNAI",
        desc: "Kamu tidak perlu repot membawa uang tunai dengan menggunakan fasilitas pembayaran nirtunai. Kamu bisa mengisi dana gelang yang diberikan di konter kasir atau kios top-up yang tersebar di area publik"
        //   title: "CASHLESS EASE",
        // desc: "You don't have to worry about carrying cash to the park, and utilize our cashless payment facility instead. You can top up the credit on your assigned wristband at our cashier counter or top-up booths throughout the public area. "
    },
    {
        image: safe_deposit,
        title: "HATI-HATI MELETAKAN BARANG",
        desc: "Jangan meninggalkan barang-barang tanpa pengawasan. Bersantailah dengan lebih tenang dan simpan barang-barang berhargamu di loker menghindari dari kehilangan, dicuri, atau rusak terkena air."
        //    title: "MIND YOUR BELONGINGS",
        // desc: "Do not leave your belongings unattended. Put your mind at ease and store your valuables in the safety lockers to prevent from being misplaced, stolen, or damaged by the water. "
    },
    {
        image: measure,
        title: "UKUR DIRI",
        desc: "Periksa ketentuan usia dan tinggi badan sebelum Anda mengantri untuk memastikan dapat bermain."
        //   title: "MEASURE YOURSELF",
        // desc: "Check the age and height restrictions before you queue to make sure you are eligible to play. "
    },
],
en : [
    {
        image: swimming,
        title: "DRESS CODE",
        desc: `
        To ensure everyone’s comfort, we kindly ask visitors to dress appropriately at the family-friendly Wahoo Waterworld.

<br></br>All visitors are required to wear proper swimwear when using all attractions at Wahoo Waterworld.

<br></br>Wahoo Waterworld reserves the right to deny entry or remove guests who are not in compliance with the dress code, are wearing unsafe clothing, or clothing that causes discomfort to others or is otherwise inappropriate for Wahoo Waterworld. Guests requiring special accommodations must inform the public safety management team upon arrival at Wahoo Waterworld.
        `
    },
    {
        image: sunblock,
        title: "STAY SAFE IN THE SUN",
        desc: "Stay comfortable while sunbathing by wearing sunscreen and bringing sunglasses."
    },
    {
        image: lifeguard,
        title: "FOLLOW THE RULES",
        desc: "To ensure safety, follow the instructions of lifeguards and comply with Wahoo Waterworld's rules."
    },
    {
        image: sunrise,
        title: "ARRIVE EARLY",
        desc: "Wahoo Waterworld opens at 9 AM, so be sure to arrive early to make the most of your fun-filled day."
    },
    {
        image: hungry,
        title: "FEELING HUNGRY?",
        desc: "Wahoo Waterworld offers a variety of food and drink outlets to satisfy your hunger and thirst. Laguna Café serves a variety of Indonesian, Asian, and Western dishes, while Odie's Food Street offers local and Asian snacks. You can also grab light snacks, beverages, and ice cream at Oz’s Snacks. "
    },
    {
        image: lifejacket,
        title: "TOWELS & LIFE JACKETS",
        desc: "Please bring your own towel. Life jackets are recommended (if needed)."
    },

    {
        image: team,
        title: "ASK OUR TEAM",
        desc: "Feel free to approach any of the friendly, uniformed Wahoo Waterworld staff around the park if you have any questions or need assistance."
    },

    {
        image: cashless,
        title: "CASHLESS CONVENIENCE",
        desc: "No need to carry cash! Use our cashless payment system. You can top up your wristband at the cashier counters or top-up kiosks located around the public area. "
    },
    {
        image: safe_deposit,
        title: "KEEP YOUR BELONGINGS SAFE",
        desc: "Don’t leave your belongings unattended. Relax with peace of mind and store your valuables in a locker to avoid loss, theft, or water damage."
    },
    {
        image: measure,
        title: "KNOW YOUR LIMITS",
        desc: "Check age and height requirements before lining up to ensure you can enjoy the rides. "
    },

]
}
const TipsData = () => {
    const { currentLanguage } = useLanguage();
    const selectedTips = tipsData[currentLanguage] || tipsData['id'];
  
    return selectedTips;
  };
  
export default TipsData;