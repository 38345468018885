import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCreative, Autoplay } from "swiper";
import placeholder_banner from "../../assets/banner-img1.jpg";
import placeholder_banner1 from "../../assets/banner-img2.jpg";
import { motion } from "framer-motion";
import ImageClip from '../ui/ImageClip';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-creative";


export default function SliderVenue({ data }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const images = [placeholder_banner, placeholder_banner1];
  const hasMultipleImages = data && data.length > 0 && data.some(el => {
    let imageUrls;
    try {
      imageUrls = JSON.parse(el.image_url);
    } catch (error) {
      imageUrls = [el.image_url];
    }
    return Array.isArray(imageUrls) && imageUrls.length > 1;
  });

  const config = {
    autoplay: {
      delay: 3000,
    },
    spaceBetween: 0,
    slidesPerView: 1,
    loop: hasMultipleImages,
    speed: 500,
    pagination: {
      clickable: true,
    },
    navigation: false,
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: true,
        translate: ["100%", 0, 0],
      },
      next: {
        shadow: true,
        translate: ["100%", 0, 0],
      },
    },
    modules: [Pagination, Navigation, EffectCreative, Autoplay],
  };
  return (
    <div className="card-custom-swiper" >
    <Swiper {...config}>
    {data && data.length > 0
      ? data.map((el, idx) => {
          let imageUrls;
          try {
            imageUrls = JSON.parse(el.image_url);
          } catch (error) {
            imageUrls = [el.image_url];
          }

          return imageUrls.map((imgUrl, imgIdx) => (
            <SwiperSlide key={`${idx}-${imgIdx}`} style={{ overflow: 'hidden' }}>
              <ImageClip  src={baseUrl + imgUrl} alt={el.name} style={{ position: 'relative', width: '100%', height: '300px', overflow: 'hidden' }} />
            </SwiperSlide>
          ));
        })
      : images.map((el, idx) => (
          <SwiperSlide key={idx}>
            <motion.div
              className="image"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img src={el} alt="placeholder" />
            </motion.div>
          </SwiperSlide>
        ))}
    </Swiper>
    </div>

  );
}
