import { useState, useEffect } from 'react';
import './style.scss';
import useFetch from '../../customFunction/useFetch';
import { useLanguage } from '../../lang/LangProvider';

const Fader = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'metadata');
    const [fadeProp, setFadeProp] = useState({ fade: 'fade-in' });

    const [index, setIndex] = useState(0);

    const textFade = [
        { text: <Text1 /> },
        { text: <Text2 /> },
        data?.sold_out && { text: <Text3 /> },
    ].filter(Boolean); 

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeProp({ fade: 'fade-out' });
            setTimeout(() => {
                setFadeProp({ fade: '' });
                setIndex((prevIndex) => (prevIndex + 1) % textFade.length); // Circular rotation
            }, 1000);
        }, 4000);
        return () => {
            clearInterval(interval);
        };
    }, [textFade.length]);

    return (
        <div className={`fade fade-in ${fadeProp.fade}`}>
            {textFade[index].text}
        </div>
    );
}

const Text1 = () => {
    const { translate } = useLanguage();
    return (
        <>
            <h2 dangerouslySetInnerHTML={{ __html: translate('home.seluncuran_air') }}></h2>
        </>
    );
}

const Text2 = () => {
    const { translate } = useLanguage();
    return (
        <>
            <h2 dangerouslySetInnerHTML={{ __html: translate('home.petualangan') }}></h2>
        </>
    );
}

const Text3 = () => {
    const date = new Date();
    const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    const formattedDate = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;

    return (
        <>
            <h2>
                <span className="white anim">Tiket {formattedDate}</span>
                <span className="blue anim"> Sold Out</span>
            </h2>
        </>
    );
}

export default Fader;