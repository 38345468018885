import './style.scss';
import Slider from "../Slider";
const TicketList = ({ data }) => {
    return (
        <>
            {data.map((dataticket, index) => (
                <div key={index} className="ticket-list">
                    <div className='ticket-image'>
                        {/* <a href={process.env.REACT_APP_BOOKING} target='_blank'> */}
                        {/* <Link to="/booking"> */}
                         {dataticket && <Slider data={[dataticket]} />}
                        {/* </Link> */}
                        {/* </a> */}
                    </div>
                    <div className="ticket-detail">
                        {/* <Link to="/booking"> */}
                            {/* <a href={process.env.REACT_APP_BOOKING} target='_blank'> */}
                            <h5 className="main-title ticket-name" dangerouslySetInnerHTML={{ __html:dataticket.name}}/>
                            {/* </a> */}
                        {/* </Link> */}
                        <p dangerouslySetInnerHTML={{ __html:dataticket.description}}/>
                        <table>
                            <tbody>
                                <tr className='text-orange text-bold'>
                                    <td >Validity</td>
                                    <td>Price</td>
                                </tr>
                                <tr>
                                    <td dangerouslySetInnerHTML={{ __html:dataticket.periode}}/>
                                    <td>{dataticket.price_format}</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <a target='_blank' href={process.env.REACT_APP_BOOKING}> */}
                        {/* <Link to="/booking">
                            <ButtonBubble className="ticket-button">
                                <div className="icon">
                                    <img src={ticketLogo} alt="" />
                                </div>
                                <div className="button-text">Buy Tickets</div>
                            </ButtonBubble>
                        </Link> */}
                        {/* </a> */}
                    </div>
                </div>
            ))}
        </>
    );
}

export default TicketList;