import './style.scss';
import ButtonLink from '../ui/ButtonLink';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCreative, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import { Link } from 'react-router-dom';
const AttractionOther = ({ data, start }) => {
    // console.log(start)
    const startSlide = start === 99 ? 0 : start-1;
    const config = {
        autoplay: false,
        spaceBetween: 30,
        slidesPerView: 1,
        autoHeight: true,
        // loop: false,
        speed: 500,
        pagination: false,
        navigation: true,
        initialSlide: startSlide,
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        modules: [Pagination, Navigation, EffectCreative, Autoplay],
    };
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return (
        <>
            <div className="attraction-div">
                <Swiper {...config}>
                    {
                        data.map((list, index) => (
                            <SwiperSlide key={index}>
                                <div className="attracrion-list-other">
                                    <div className="main-image">
                                            <Link to={`../ride-attraction/${list.seo_url}`}  >
                                            <img src={baseUrl + JSON.parse(list.image_url).main_image} alt={list.name} />
                                            </Link>
                                    </div>

                                    <div className="card-desc">
                                        <Link to={`../ride-attraction/${list.seo_url}`} >
                                            <h5 className='title-other secondary-title'>{list.name}</h5>
                                        </Link>
                                        <p>{list.category_name}<span className='mx-2'>|</span><span className='text-blue text-medium'>{`Min. Tinggi ${list.age} cm`}</span></p>
                                        <p className='mb-big-1'>{list.description}</p>
                                        <ButtonLink btnColor="bg-white" link={`../ride-attraction/${list.seo_url}`} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </>
    );
}

export default AttractionOther;